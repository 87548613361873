<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <fieldset :disabled="formSubmitting">
      <h2 class="mb-6 text-lg font-bold">
        {{ t('users.profile.delete_account.title') }}
      </h2>

      <p class="mb-4">
        {{ t('users.profile.delete_account.warning') }}
      </p>

      <p class="mb-4">
        {{ t('users.profile.delete_account.instructions') }}
      </p>

      <form-group
        name="deletion_confirmation"
        rules="required"
        :label="false"
      />

      <app-button
        type="submit"
        :disabled="formSubmitting || invalid"
        :loading="formSubmitting"
        feature="confirm"
        class="float-right mt-4"
      />
    </fieldset>
  </form>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useForm as useVVForm } from 'vee-validate'
import { useI18n } from 'vue-i18n'

import { deleteUser } from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const store = useStore()
const router = useRouter()
const { t } = useI18n()
const {
  setErrors,
  values,
  errors,
} = useVVForm()

const formSubmitting = ref(false)
const success = ref(false)

const invalid = computed(() => (
  Object.keys(errors.value).length > 0
))

function handleSubmit() {
  if (!formSubmitting.value) {
    formSubmitting.value = true

    const params = {
      data: {
        type: 'users',
        attributes: { ...values },
      },
    }

    deleteUser(params)
      .then(() => {
        success.value = true

        // Disconnect current user
        store.commit('loading/ENABLE')
        store.dispatch('auth/signOutFromApi')
          .then(async () => {
            await router.push({ name: 'auth' })
          })
          .finally(() => {
            store.commit('loading/RESET')
          })

        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('users.profile.delete_account.success'),
            type: 'success',
          },
        )
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          setErrors({ ...e.response.data.errors })
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}
</script>
