<template>
  <user-credential-form
    :resource="user"
    :additional-errors="userFormErrors"
    :submitting="submitting"
    :success="success"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import { useStore } from 'vuex'
import { ref } from 'vue'

import {
  sendCredentialVerification as apiSendCredentialVerification,
} from '@shared/http/api'
import UserCredentialForm from '@app/components/resources/user/UserCredentialForm.vue'

// ---------- USER ----------

const store = useStore()
const user = ref(store.state.auth.user)

// ---------- FORM ----------

const success = ref(false)
const submitting = ref(false)
const userFormErrors = ref({})

function handleSubmit(attributes) {
  submitting.value = true

  // Create a new verification procedure
  apiSendCredentialVerification({
    data: {
      attributes,
    },
  }).then(() => {
    success.value = true
  }).catch(() => {
    success.value = false
  }).finally(() => {
    submitting.value = false
  })
}
</script>
