<template>
  <div
    v-if="success"
  >
    <app-heading class="mb-4">
      {{ t('user_credential_verifications.verify.sent.title') }}
    </app-heading>

    <p class="mb-2">
      {{ t('user_credential_verifications.verify.sent.email_1', { email: values.credential }) }}
    </p>

    <p class="mb-3">
      {{ t('user_credential_verifications.verify.sent.email_2') }}
    </p>

    <p class="mb-3">
      {{ t('user_credential_verifications.verify.sent.email_3') }}
    </p>

    <app-button
      emphasis="low"
      :label="t('user_credential_verifications.verify.sent.again')"
      whitespace-classes="whitespace-normal"
      width="full"
      :loading="submitting"
      @click="handleSubmit"
    />
  </div>

  <!--
    Use v-show instead of v-if,
    to not lose form values
  -->
  <form
    v-show="!success"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <fieldset :disabled="submitting">
      <p class="text-xs mb-1">
        {{ t('auth.credential_verification.intro') }}
      </p>

      <p class="text-xs mb-3">
        {{ t('auth.credential_verification.intro2') }}
      </p>

      <form-group
        name="credential"
        rules="email|required"
        type="email"
        :label="t('validation.attributes.email')"
        :initial-value="resource.attributes.email"
        margins="mb-1"
      />

      <app-button
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
        feature="save"
        class="float-right mt-4"
      />
    </fieldset>
  </form>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppHeading from '@app/components/ui/AppHeading.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
  // Link succeed to send or not
  success: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'submitted',
])

const { t } = useI18n()

const {
  handleSubmit,
  invalid,
  values,
} = useForm(props, { emits })
</script>
