<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <fieldset :disabled="submitting">
      <template v-if="mode === 'profile'">
        <!-- Profile picture form -->
        <app-card-item class="mt-6 p-4 flex items-center">
          <!-- Profile picture preview -->
          <app-rounded-picture
            :picture-url="profilePictureUrl"
            picture-size="24"
            with-shadow
            class="mr-6"
          />

          <div class="flex-1 text-center">
            <form-label
              :label="t('validation.attributes.profile_picture')"
              class="mb-3 block"
            />

            <!-- Edit profile picture -->
            <label class="app-link block text-sm">
              {{ t('common.actions.edit') }}

              <input
                type="file"
                class="hidden"
                :accept="imageMimeTypes"
                @change="handleFileUpload"
              >
            </label>

            <form-error-messages
              name="image.file"
              :data-form-errors="true"
              :error-messages="imageErrors.flat()"
            />

            <!-- Delete profile picture -->
            <app-button
              v-if="profilePictureDeletable"
              color="red"
              font-size="sm"
              :label="t('common.actions.delete')"
              emphasis="low"
              @click="handleDeleteProfilePicture"
            />
          </div>

          <form-group
            name="image.id"
            type="hidden"
          />

          <form-group
            name="image._destroy"
            type="hidden"
          />

          <form-group
            name="image.file"
            :rules="imageRules"
            type="hidden"
          />

          <form-group
            name="image.name"
            :initial-value="t('validation.attributes.profile_picture')"
            type="hidden"
          />

          <form-group
            name="image.collection_name"
            initial-value="image"
            type="hidden"
          />
        </app-card-item>

        <!-- Details form -->
        <h2 class="mt-6 mb-6 text-lg font-bold">
          {{ t('users.profile.your_personal_information') }}
        </h2>

        <form-group
          name="name"
          error-label-i18n-key="nickname"
          :label="t('validation.attributes.nickname')"
          :hint="t('form.hints.profile.name')"
          type="text"
        />

        <form-group
          name="first_name"
          type="text"
        />

        <form-group
          name="last_name"
          type="text"
        />

        <form-group
          name="address.id"
          type="hidden"
        />

        <form-group
          name="address.country"
          type="select"
          :form-control-props="{
            options: countries,
          }"
        >
          <template #control-vue-multiselect-option="option">
            <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
          </template>

          <template #control-vue-multiselect-singlelabel="option">
            <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
          </template>
        </form-group>
      </template>

      <!-- Password form -->
      <template v-if="mode === 'password'">
        <h2 class="mb-6 text-lg font-bold">
          {{ t('users.profile.edit_password') }}
        </h2>

        <form-group
          v-if="props.resource.attributes.has_password"
          name="current_password"
          type="password"
          rules="required"
          :form-control-props="{
            autocomplete: 'new-password',
          }"
        />

        <form-group
          :label="t('validation.attributes.new_password')"
          name="password"
          type="password"
          rules="required"
          hide-errors
          :form-control-props="{
            autocomplete: 'new-password',
          }"
        >
          <template #hint>
            <form-password-hint-list
              :password="form.values.password"
            />
          </template>
        </form-group>

        <form-group
          :label="t('validation.attributes.new_password_confirmation')"
          name="password_confirmation"
          type="password"
          rules="confirmed:@password"
          :form-control-props="{
            autocomplete: 'new-password',
          }"
        />
      </template>

      <!-- Email form -->
      <template v-if="mode === 'email'">
        <h2 class="mb-6 text-lg font-bold">
          {{ t('users.profile.edit_email') }}
        </h2>

        <form-group
          name="email"
          rules="email|required"
          type="email"
        />
      </template>

      <!-- Devise form -->
      <template v-if="mode === 'currency'">
        <form-group
          name="currency_id"
          rules="required"
          type="select"
          :form-control-props="{
            filterable: false,
            options: handleCurrenciesSearch,
          }"
        />
      </template>

      <!-- Locale form -->
      <template v-if="mode === 'locale'">
        <form-group
          name="locale"
          type="select"
          :form-control-props="{
            filterable: false,
            options: staticLocalesOptionsTranslated,
          }"
        />
      </template>

      <app-button
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
        feature="save"
        class="float-right mt-4"
      />
    </fieldset>
  </form>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { uniqBy } from 'lodash'
import { useField } from 'vee-validate'

import { fetchCurrencies as apiFetchCurrencies } from '@shared/http/api'
import useForm from '@shared/hooks/form/form'
import useGeoloc from '@shared/hooks/geoloc'
import useResource from '@shared/hooks/resources/resource'
import useLocale from '@shared/hooks/locale'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormLabel from '@shared/components/form/FormLabel.vue'
import FormErrorMessages from '@shared/components/form/FormErrorMessages.vue'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormPasswordHintList from '@shared/components/form/password_hint/FormPasswordHintList.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
  // Edit mode
  // Possible values: "profile", "password", "email", "phone"
  mode: {
    type: String,
    default: 'profile',
  },
})

const emits = defineEmits([
  'submitted',
])

const { t } = useI18n()
const { countries } = useGeoloc()
const { staticLocalesOptionsTranslated } = useLocale()

const {
  handleSubmit,
  invalid,
  form,
} = useForm(props, { emits })

// ---------- PROFILE PICTURE ----------

const {
  errors: imageErrors,
} = useField('image.file')

const imageMimeTypes = import.meta.env.VITE_VALID_IMAGE_MIMETYPES
const imageMaxSize = import.meta.env.VITE_VALID_MAX_FILE_SIZE_KB
const imageMaxWidth = import.meta.env.VITE_VALID_MAX_IMAGE_WIDTH
const imageMaxHeight = import.meta.env.VITE_VALID_MAX_IMAGE_HEIGHT

const imageRules = [
  `mimes:${imageMimeTypes}`,
  `size.file:${imageMaxSize}`,
  `dimensions:max_width=${imageMaxWidth},max_height=${imageMaxHeight}`,
].join('|')

const { getImageUrlFromResource } = useResource()

const avatarUrl = computed(() => (
  getImageUrlFromResource(props.resource, 'sm')
))

function handleFileUpload(e) {
  // Check if a file is present,
  // useful when upload is cancel
  const targetFile = e.target.files?.[0]
  if (targetFile) {
    // assign new picture value
    form.setFieldValue('image.file', targetFile)
    // unmark picture destruction (if it was)
    form.setFieldValue('image._destroy', false)
  }
}

function handleDeleteProfilePicture() {
  // unassign picture value
  form.setFieldValue('image.file', null)
  // mark picture for destruction
  form.setFieldValue('image._destroy', true)
}

const placeholderPath = '/images/shared/placeholders/users.png'

const profilePictureUrl = computed(() => {
  // no picture url if it is marked for destruction
  if (!form.values.image?._destroy) {
    // uploaded picture
    if (form.values.image?.file) {
      return URL.createObjectURL(form.values.image.file)
    }
    // or user's current picture
    return avatarUrl.value
  }

  return placeholderPath
})

// is there a profile picture to delete
const profilePictureDeletable = computed(() => (
  profilePictureUrl.value && profilePictureUrl.value !== placeholderPath
))

// ---------- CURRENCY ----------

// Return currencies options used in select control,
// based on currencies resources
function formatCurrenciesOptions(currencies) {
  return currencies.map((currencyResource) => ({
    label: currencyResource.attributes.name,
    value: currencyResource.id,
  }))
}

const initialCurrenciesSearch = ref(true)

function handleCurrenciesSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    if (initialCurrenciesSearch.value) {
      initialCurrenciesSearch.value = false

      if (props.resource?.relationships?.currency) {
        // provide an option with the resource's relationship value
        options.push(formatCurrenciesOptions([props.resource.relationships.currency])[0])
      }
    }

    apiFetchCurrencies({
      'filter[name]': searchKeywords,
    })
      .then((response) => {
        // get the new options from the response
        options = options.concat(formatCurrenciesOptions(response.data.data))
      })
      .finally(() => {
        resolve(uniqBy(options, 'value'))
      })
  })
}
</script>
