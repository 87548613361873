<template>
  <user-private-profile-resource
    v-if="user && !loading"
    :resource="user"
  />

  <user-profile-empty
    v-else-if="loading"
  />
</template>

<script setup>
import { ref } from 'vue'

import { fetchCurrentUser } from '@shared/http/api'
import UserPrivateProfileResource from '@app/components/resources/user/UserPrivateProfileResource.vue'
import UserProfileEmpty from '@app/components/resources/user/UserProfileEmpty.vue'

const user = ref(null)
const loading = ref(false)
const params = {
  'mode': 'private-profile',
}

function fetchUser() {
  loading.value = true

  fetchCurrentUser(params)
    .then((response) => {
      user.value = response.data.data
    })
    .finally(() => {
      loading.value = false
    })
}

fetchUser()
</script>
