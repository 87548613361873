<template>
  <user-public-profile-resource
    v-if="user && !loading"
    :resource="user"
    @contact-updated="handleContactUpdated"
  />

  <user-profile-empty
    v-else-if="loading"
  />
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import { fetchUser as fetchApiUser } from '@shared/http/api'
import UserProfileEmpty from '@app/components/resources/user/UserProfileEmpty.vue'
import UserPublicProfileResource from '@app/components/resources/user/UserPublicProfileResource.vue'

const route = useRoute()

const user = ref(null)
const loading = ref(false)

function fetchUser() {
  const { id } = route.params
  loading.value = true

  fetchApiUser(id)
    .then((response) => {
      user.value = response.data.data
    })
    .finally(() => {
      loading.value = false
    })
}

function handleContactUpdated() {
  fetchUser()
}

fetchUser()
</script>
